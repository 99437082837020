<template>
  <div>
    <Navbar page="Entregáveis" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">
      <h5 @click="obrigatorios = !obrigatorios" class="text-base md:text-xl font-semibold text-gray-800 mb-3 mt-3 bg-yellow-300 rounded py-2 px-2 relative cursor-pointer" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
        Entregáveis Obrigatórios
        <svg v-if="!obrigatorios" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute right-4 top-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
        <svg v-if="obrigatorios" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute right-4 top-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
        </svg>
      </h5>
      <div v-if="obrigatorios" class="grid grid-cols-12 gap-6">
        <div class="col-span-12 md:col-span-4 xl:col-span-3 mb-1 mt-1">
          <router-link to="/entregaveis/ropa" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
            </svg>
            <span class="text-sm">Registro de Operações</span>
          </router-link>
        </div>
        <div class="col-span-12 md:col-span-4 xl:col-span-3 mb-1 mt-1">
          <router-link to="/entregaveis/riscos" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
            <span class="text-sm">RIPD / DPIA</span>
          </router-link>
        </div>
        <div class="col-span-12 md:col-span-4 xl:col-span-3 mb-1 mt-1 opacity-20">
          <router-link to="#" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
            </svg>
            <span class="text-sm">Politica de Privacidade</span>
            <span class="inline-block bg-red-500 text-xs text-white py-1 px-2 rounded-full mt-1">Em Breve</span>
          </router-link>
        </div>
        <div class="col-span-12 md:col-span-4 xl:col-span-3 mb-1 mt-1 opacity-20">
          <router-link to="#" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" />
            </svg>
            <span class="text-sm">Teste de Legítmo Interesse</span>
            <span class="inline-block bg-red-500 text-xs text-white py-1 px-2 rounded-full mt-1">Em Breve</span>
          </router-link>
        </div>
      </div>
      <h5 @click="opcionais = !opcionais" class="text-base md:text-xl font-semibold text-gray-800 mb-3 mt-3 bg-yellow-300 rounded py-2 px-2 relative cursor-pointer" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
        Entregáveis Opcionais / Boas Práticas
        <svg v-if="!opcionais" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute right-4 top-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
        <svg v-if="opcionais" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute right-4 top-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
        </svg>
      </h5>
      <div v-if="opcionais" class="grid grid-cols-12 gap-6">
        <div class="col-span-12 md:col-span-4 xl:col-span-3 mb-1 mt-1">
          <router-link to="/entregaveis/conformidade" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M13 10V3L4 14h7v7l9-11h-7z" />
            </svg>
            <span class="text-sm">Relatório de Não Conformidades</span>
          </router-link>
        </div>
        <div class="col-span-12 md:col-span-4 xl:col-span-3 mb-1 mt-1">
          <router-link to="/entregaveis/diagnostico" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
            </svg>
            <span class="text-sm">Relatórios Diagnóstico</span>
          </router-link>
        </div>
        <div class="col-span-12 md:col-span-4 xl:col-span-3 mb-1 mt-1">
          <router-link to="/entregaveis/especifico" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
            </svg>
            <span class="text-sm">Relatórios Específicos</span>
          </router-link>
        </div>
        <div class="col-span-12 md:col-span-4 xl:col-span-3 mb-1 mt-1">
          <router-link to="/entregaveis/agentestratamento" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
            <span class="text-sm">Relatório Agentes de tratamento</span>
          </router-link>
        </div>
        <div class="col-span-12 md:col-span-4 xl:col-span-3 mb-1 mt-1 opacity-20">
          <router-link to="#" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
            </svg>
            <span class="text-sm">Relatório Visuais</span>
            <span class="inline-block bg-red-500 text-xs text-white py-1 px-2 rounded-full mt-1">Em Breve</span>
          </router-link>
        </div>
        <div class="col-span-12 md:col-span-4 xl:col-span-3 mb-1 mt-1">
          <router-link to="/entregaveis/treinamento" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-12" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
            </svg>

            <span class="text-sm">Relatório Treinamento</span>
          </router-link>
        </div>
        <div class="col-span-12 md:col-span-4 xl:col-span-3 mb-1 mt-1">
          <router-link to="/entregaveis/incidentes" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-12" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z" />
            </svg>
            <span class="text-sm">Gestão de Incidentes</span>
          </router-link>
        </div>
        <div class="col-span-12 md:col-span-4 xl:col-span-3 mb-1 mt-1">
          <router-link to="/entregaveis/cookies" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-12" fill="#000000" viewBox="0 0 256 256"><path d="M163.07,164.93a10,10,0,1,1-14.14,0A10,10,0,0,1,163.07,164.93Zm-78.14-8a10,10,0,1,0,14.14,0A10,10,0,0,0,84.93,156.93Zm6.14-41.86a10,10,0,1,0-14.14,0A10,10,0,0,0,91.07,115.07Zm33.86,1.86a10,10,0,1,0,14.14,0A10,10,0,0,0,124.93,116.93ZM230,128A102,102,0,1,1,128,26a6,6,0,0,1,6,6,42,42,0,0,0,42,42,6,6,0,0,1,6,6,42,42,0,0,0,42,42A6,6,0,0,1,230,128Zm-12.18,5.65A54.09,54.09,0,0,1,170.3,85.7a54.09,54.09,0,0,1-48-47.53,90,90,0,1,0,95.47,95.48Z"></path></svg>
            <span class="text-sm">Relatório dos cookies</span>
          </router-link>
        </div>
        <div class="col-span-12 md:col-span-4 xl:col-span-3 mb-1 mt-1">
          <router-link to="/entregaveis/evidencias" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-12" fill="#000000" viewBox="0 0 256 256"><path d="M224,48H32A16,16,0,0,0,16,64V88a16,16,0,0,0,16,16v88a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V104a16,16,0,0,0,16-16V64A16,16,0,0,0,224,48ZM208,192H48V104H208ZM224,88H32V64H224V88ZM96,136a8,8,0,0,1,8-8h48a8,8,0,0,1,0,16H104A8,8,0,0,1,96,136Z"></path></svg>
            <span class="text-sm">Relatório de evidências</span>
          </router-link>
        </div>
      </div>
      <h5 @click="documentos = !documentos" class="text-base md:text-xl font-semibold text-gray-800 mb-3 mt-3 bg-yellow-300 rounded py-2 px-2 relative cursor-pointer" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
        Outros documentos
        <svg v-if="!documentos" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute right-4 top-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
        <svg v-if="documentos" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute right-4 top-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
        </svg>
      </h5>
      <div v-if="documentos" class="grid grid-cols-12 gap-6">
        <div class="col-span-12">
          <button @click="$modal.show('documentoApoio')" type="button" class="mb-3 inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
            Adicionar documento
          </button>
          <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Nome
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Data
                      </th>
                      <th  scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Opção
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="item in docspoio" :key="item._id">
                      <td class="px-6 py-4 whitespace-nowrap">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{item.nome ? item.nome : ''}}
                          </div>
                        </div>
                      </td>

                      <td class="px-6 py-4 whitespace-nowrap">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{objectIdToDate(item._id)}}
                          </div>
                        </div>
                      </td>

                      <td  class="px-6 py-4 whitespace-nowrap">
                        <div class="flex items-center">
                          <div class="text-sm text-blue-500">
                            <a target="_blank" :href="`${url_api}/upload?mimetype=${item.arquivo.mimetype}&filename=${item.arquivo.filename}&folder=empresas`">Visualizar</a>
                            <button
                              type="button"
                              @click="remove(item._id)" 
                              class="inline-flex justify-center py-1 px-2 mx-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                              Remover
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <pagination v-model="page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
            </div>
          </div>
      </div>
      </div>
    </div>
    <modal name="documentoApoio" :width="800" :height="'auto'" :adaptive="true" :reset="true">
      <div class="px-3 py-2 overflow-y-auto h-full">
        <h2 class="text-xl font-semibold mb-1"> Adicionar documento </h2>
        <vue-dropzone ref="doc" id="doc" class="w-full" :options="dropzoneOptions" v-on:vdropzone-success="update"></vue-dropzone>
      </div>
    </modal>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
export default {
    components: {
      vueDropzone,
    },
    data() {
        return {
            obrigatorios: true,
            opcionais: true,
            documentos: true,
            docspoio: [],
            page: 1,
            perPage: 15,
            total: 0,
            dropzoneOptions: this.$http.getDropzoneConfig(
            `v1/upload`,
            'post',
            {
              maxFiles: 1,
              addRemoveLinks: true,
              capture: true,
              dictDefaultMessage: 'Clique ou arraste o arquivo aqui',
              dictRemoveFile: 'Remover'
            }
      ),
        }
    },
    methods: {
      objectIdToDate(objectId) {
          if (typeof objectId !== 'string' || objectId.length !== 24) {
              throw new Error('ObjectId inválido');
          }
          const hexTimestamp = objectId.substring(0, 8);
          const timestamp = parseInt(hexTimestamp, 16);
          const date = new Date(timestamp * 1000);
          function padZero(num) {
              return num < 10 ? '0' + num : num;
          }

          const day = padZero(date.getDate());
          const month = padZero(date.getMonth() + 1); // Mês é 0-indexado
          const year = date.getFullYear();
          const hours = padZero(date.getHours());
          const minutes = padZero(date.getMinutes());
          const seconds = padZero(date.getSeconds());

          const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

          return formattedDate;
      },
      async list() {
        const listReq = await this.$http.post(`/v1/docapoio/list`, { limit: this.perPage });
        this.docspoio = listReq.data.data;
        this.total = listReq.data.total;
      },
      async update(file, response) {
        this.$refs.doc.removeFile(file);
        const req = await this.$http.post(`/v1/docapoio/`, { arquivo: response.file });
        if (req.data.success) {
            this.$vToastify.success("Adicionado com sucesso!");
            this.list();
            this.$modal.hide('documentoApoio')
        } else {
            this.$vToastify.error(req.data.err);
        }
      },
      async remove(id){
        this.$confirm({
          title: 'Confirmação Remoção',
          message: `Tem certeza que deseja remover o arquivo?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
              if (confirm) {
                  await this.$http.delete(`/v1/docapoio/${id}`);
                  this.$vToastify.success("Removido com sucesso!");
                  this.list();
              }
          }
        });
      },
      async setPage(page) {
        this.page = page;
        const skip = (page - 1) * this.perPage;
        const limit = this.perPage;

        const listReq = await this.$http.post(`/v1/docapoio/list`, { limit, skip });
        this.docspoio = listReq.data.data;
        this.total = listReq.data.total;
      }
    },
    async beforeMount() {
      this.list();
    },
}
</script>